import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ModalFieldComponent } from '../../../forms/modal-field.component';
import { AppState } from '../../../reducers/index';
import { getMfaBackupEmail, MfaService } from '../../../reducers/mfa/mfa.service';
import { ModalInfoComponent } from '../../modal-info/modal-info.component';

@Component({
  selector: 'configure-backup-email',
  templateUrl: './configure-backup-email.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, ModalFieldComponent, ModalInfoComponent],
})
export class ConfigureBackupEmailComponent implements OnInit {
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public mode: string;

  public backupEmail$: Observable<string>;

  public password: string;

  public constructor(
    private store: Store<AppState>,
    private mfaService: MfaService,
  ) {
    this.backupEmail$ = this.store.select(getMfaBackupEmail);
  }

  public ngOnInit() {
    this.password = this.mfaService.password;
  }
}
