<div>
  <h5>{{ 'Back-up email' | translate }}</h5>
  <p>
    {{
      'Your back-up email can be used to send a magic link to you whenever you can’t access your Multifactor authentication device. Make sure this email is always up-to-date to minimize the chance of getting locked-out of your account.'
        | translate
    }}
  </p>

  <modal-info *ngIf="backupEmail$ | async" class="mb-4" [label]="'Current back-up email' | translate">
    <span>{{ backupEmail$ | async }}</span>
  </modal-info>

  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'email'">
      <modal-field [label]="'Back-up email' | translate" [control]="form.get('backup_email')">
        <input
          class="form-modal__input"
          type="text"
          name="backup_email"
          [placeholder]="'Back-up email' | translate"
          [formControl]="form.get('backup_email')"
        />
      </modal-field>
      <modal-field *ngIf="!password" [label]="'Password' | translate" [control]="form.get('password')">
        <input
          class="form-modal__input"
          type="password"
          name="password"
          [placeholder]="'Password' | translate"
          [formControl]="form.get('password')"
        />
      </modal-field>
    </ng-container>
    <ng-container *ngSwitchCase="'code'">
      <modal-field [label]="'Verification code' | translate" [control]="form.get('verification_code')">
        <input
          class="form-modal__input"
          type="text"
          name="verification_code"
          [formControl]="form.get('verification_code')"
        />
      </modal-field>
    </ng-container>
  </ng-container>
</div>
